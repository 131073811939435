html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif, system ui, -apple-system, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes zoom_in_out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.home-hero-img {
  height: auto;
  animation: zoom_in_out 40s linear infinite;
}

.slick-dots {
  position: absolute;
  z-index: 40;
  display: block;
  width: 100%;
  padding: 0;
  margin-top: 0;
  list-style: none;
  text-align: center;
  margin-top: 52px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
  background-color: inherit;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ft-slick__dots--custom {
  height: 8px;
  width: 8px;
  background-color: #64648c52;
  border-radius: 4px;
  position: relative;
}

.slick-dots li {
  width: 10px;
  margin: 0 6px;
  transition: width 0.2s ease-in-out;
}

.slick-dots .slick-active {
  width: 23px;
  height: 8px;
  margin: 0 6px;
  transition: width 0.2s ease-in-out;
  background-color: #a8a8fd;
  border-radius: 4px;
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 23px;
  top: -0.9px;
  margin: 0 2px;
  overflow: hidden;
}

.slick-dots .slick-active .ft-slick__dots--custom .loading {
  height: 8px;
  background-color: #a8a8fd;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots .slick-active .ft-slick__dots--hero {
  width: 23px;
  top: -0.9px;
  margin: 0 2px;
  overflow: hidden;
}

.ft-slick__dots--hero {
  height: 8px;
  width: 8px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}

.slick-dots .slick-active .ft-slick__dots--hero .hero {
  height: 8px;
  background-color: #56b6a5;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots.slick-thumb li {
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: #56b6a5;
  width: 23px;
}

.slick-dots.slick-homeTesti {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots.slick-homeTesti li {
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  background-color: #64648c52;
}

.slick-dots.slick-homeTesti li.slick-active {
  width: 23px;
  z-index: 100;
  background-color: #6f6fe0;
}

.slick-slide.slick-active.slick-center.slick-current > div img {
  opacity: 1;
  transform: scale(1);
  /* padding: 0 30px; */
  width: 800px;
}

@media (max-width: 768px) {
  .slick-slide.slick-active.slick-center.slick-current > div img {
    opacity: 1;
    transform: scale(1);
    padding: 0;
    width: 800px;
  }
}

.slick-slide.slick-active.slick-cent img {
  opacity: 0.8;
}
.slide,
.slide img {
  width: 100%;
  height: 400px;
}
.slide {
  opacity: 0.7;
  transform: scale(0.7);
}

.slide-active {
  opacity: 1;
  transform: scale(1.25);
  transition: all 0.5s ease;
  margin-right: 20px;
}

div.slick-slide > div {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}

div.slick-slide {
  transition: all;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}
.raw_html ol {
  list-style-type: decimal;
  padding-left: 1em;
}
.raw_html li {
  font-size: inherit;
  margin-bottom: 4px;
}
.raw_html a {
  text-decoration: underline;
  font-size: inherit;
}
.raw_html p {
  font-size: inherit;
  margin-bottom: 1rem;
}
.raw_html h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.raw_html h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.raw_html h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.raw_html h4 {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.raw_html h5 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.raw_html h5 {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.raw_html li p {
  font-size: inherit;
  margin-bottom: 0.5rem;
}
.raw_html ul li {
  margin-left: 1.5rem;
}
.raw_html ul {
  margin-bottom: 1.5rem;
}
.raw_html li::marker {
  margin-left: 1.5rem;
}
.raw_html p:has(strong) {
  margin-bottom: 0.5rem;
}
