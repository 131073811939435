@media screen and (max-width: 767px) {
  .react-calendar {
    margin-inline: auto;
  }
}

.react-calendar__navigation {
  margin-right: 28.56px;
  margin-left: 43.21px;
  margin-top: 23.21px;
  justify-content: space-between;
  align-items: center;
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
  background-color: transparent;
}

.react-calendar__viewContainer {
  min-width: 200.37px;
  min-height: 219.24px;
  max-width: 254.37px;
  max-height: 219.24px;
  margin: 0px 40px;
}
@media screen and (max-width: 480px) {
  .react-calendar__viewContainer {
    margin: 0px;
  }
}

@media screen and (max-width: 767px) {
  .react-calendar__viewContainer {
    margin-left: auto;

    margin-right: auto;
  }
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  order: 2;
  left: 200px;
  width: 20px;
  height: 20px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  order: 3;
  right: 0px;
  width: 20px;
  height: 20px;
}

.react-calendar__navigation__label {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d5f;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .react-calendar__navigation {
    margin-top: 33.21px;
    margin-left: 32.21px;
    margin-right: 18.56px;
    margin-bottom: 30.61px;
  }
}

.react-calendar__month-view {
  min-width: 200.37px;
  min-height: 219.24px;
  max-width: 254.37px;
  max-height: 219.24px;
}

.react-calendar__month-view__weekdays__weekday {
  margin-left: 1px;
  padding: 0px;
  flex-basis: 14.2857%;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d5f;
  font-family: Inter, sans-serif;
  text-transform: capitalize;
}

.react-calendar__month-view__weekdays__weekday.react-calendar__month-view__weekdays__weekday--weekend
  abbr[title] {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d5f;
}

.react-calendar__year-view__months {
  justify-content: center;
  column-gap: 20px;
}

.react-calendar__year-view__months
  > .react-calendar__tile.react-calendar__year-view__months__month {
  padding: 10px 0px;
  margin: 0px 8px;
  column-gap: 20px;
}

.react-calendar__tile--now {
  background-color: #afdcd4;
}

.react-calendar__tile.react-calendar__month-view__days__day:hover {
  color: grey;
  background-color: #afdcd4;
}

.react-calendar__tile.react-calendar__month-view__days__day :active {
  color: white;
  background-color: #56b6a5;
}

.react-calendar__year-view {
  height: 100%;
}
.react-calendar__month-view__days {
  min-width: 270px;
}
.react-calendar__month-view__days
  .react-calendar__tile.react-calendar__month-view__days__day {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #64648c;
  min-width: 32px;
  max-width: 34px;
  margin: 1px;
  margin-left: 2.3px;
  height: 34px;
  width: 100%;
  border-radius: 500px;
}

@media screen and (min-width: 500px) {
  .react-calendar__month-view__days
    .react-calendar__tile.react-calendar__month-view__days__day {
    min-width: 34px;
    max-width: 34px;
  }
}

.react-calendar__tile--hasActive {
  background-color: #56b6a5;
  color: white;
}

.react-calendar__tile:enabled:hover {
  background-color: #d3ece7;
}

.react-calendar__tile--hasActive:enabled:hover {
  background-color: #8bcdc1;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range {
  color: white;
  background-color: #56b6a5;
}
.myClassName {
  background-color: green;
}
.react-calendar__month-view__weekdays {
  width: 100%;
}

.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

@media screen and (max-width: 500px) {
  .react-calendar__viewContainer
    .react-calendar__month-view
    .react-calendar__month-view__weekdays
    > .react-calendar__month-view__weekdays__weekday {
    max-width: 50px;
    flex-basis: 0%;
  }
}

@media screen and (max-width: 370px) {
  .react-calendar__month-view__days {
    min-width: 200px;
  }

  .react-calendar__month-view__days
    .react-calendar__tile.react-calendar__month-view__days__day {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    min-width: 32px;
    max-width: 32px;
    margin-left: 1px;
    height: 34px;
    width: 100%;
  }
  .react-calendar__month-view__weekdays {
    width: 99%;
  }

  .react-calendar__viewContainer
    .react-calendar__month-view
    .react-calendar__month-view__weekdays
    > .react-calendar__month-view__weekdays__weekday {
    margin: 0px;
    max-width: 34px;
    flex-basis: 11%;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
}

.react-calendar__month-view__days
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
